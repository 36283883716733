import Vue from 'vue';
import DatePicker from 'vue2-datepicker';
import Select from '@/components/Select';
import Dialog from '@/components/Dialog';
import MultiSelect from 'vue-multiselect';
import App from './App.vue';
import router from './router';
import store from './store';

import '@/assets/scss/app.scss';
import 'vue2-datepicker/index.css';

Vue.component('DatePicker', DatePicker);
Vue.component('Select', Select);
Vue.component('Dialog', Dialog);
Vue.component('Multiselect', MultiSelect);

Vue.config.productionTip = false;

Vue.directive('visible', (el, binding) => {
  // eslint-disable-next-line no-param-reassign
  el.style.visibility = binding.value ? 'visible' : 'hidden';
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
