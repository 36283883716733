function generateApiEndpoint(endpoint) {
  if (endpoint === 'db') {
    let slug = '';
    if (process.env.NODE_ENV === 'staging') {
      slug = '/staging';
    }
    return `${window.location.origin}${slug}/data/db.json?${new Date().getTime()}`;
  }

  let protocol = 'https://';
  let domain = 'order.icecreamandshop.com/api/';
  let params = '';
  if (process.env.NODE_ENV === 'development') {
    protocol = 'https://';
    domain = 'order.icecreamandshop.com/api/';
    params = '?m=s';
  }
  if (endpoint === 'flavors') {
    // eslint-disable-next-line prefer-template
    params += (process.env.NODE_ENV === 'development' ? '&' : '?') + 'type=circles&names';
  }
  return `${protocol}${domain}${endpoint}${params}`;
}

export default generateApiEndpoint;
