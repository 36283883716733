<template>
  <div class="page-content">
      <div class="container suggestions">
        <h1>What would you like?</h1>
          <transition-group tag="ul" @before-enter="listBeforeEnter"
          @after-enter="listEnter">
          <li
            v-for="product in products"
            :key="product.productId"
            class="col-2"
          >
            <Router-link
              :to="{path: `${$options.links.products}/${product.link}`}"
              class="holder-item align-bottom"
              @click.native="handleClickProd(product.nameOfProduct)"
            >
              <img
                :src="require(`@/assets/images/${product.img}`)"
                alt="image"
              >
              <div class="inner">
                <strong>{{ product.nameOfProduct }}</strong>
              </div>
            </Router-link>
          </li>
          </transition-group>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { gsap } from 'gsap';
import ROUTES from '@/constans/route';

export default {
  links: {
    products: ROUTES.PRODUCTS,
  },
  name: 'Home',
  computed: {
    ...mapGetters(['products', 'productSoda', 'productAffogato']),
  },
  methods: {
    handleClickProd(type, nextRoute) {
      if (type === 'Soda Floats') {
        this.$store.commit('clearBuild');
        this.$store.commit('addToBuilder', {
          id: Date.now(),
          variantId: this.productSoda.id,
          typeOfBuild: 'soda',
          img: 'preview-soda-float.jpg',
          title: 'Soda Float',
          flavors: [],
          soda: [],
          price: this.productSoda ? this.productSoda.amount / 100 : 0,
        });
      } else if (type === 'Affogatos') {
        this.$store.commit('clearBuild');
        this.$store.commit('addToBuilder', {
          id: Date.now(),
          variantId: this.productAffogato.id,
          typeOfBuild: 'affogato',
          img: 'btn-product-affogato.jpg',
          title: 'Affogato',
          flavors: [],
          price: this.productAffogato ? this.productAffogato.amount / 100 : 0,
        });
      } else if (type === 'Ice Cream Sandwiches') {
        this.$store.commit('clearBuild');
        this.$store.commit('addToBuilder', {
          id: Date.now(),
          variantId: null,
          typeOfBuild: 'sandwich',
          img: 'btn-product-sandwich.jpg',
          title: 'Ice Cream Sandwich',
          flavors: [],
          cookie: '',
          price: 0,
        });
      }

      this.$router.push(nextRoute);
    },
    /* eslint-disable */
    beforeEnter(el) {
    },
    beforeLeave(el) {
    },
    listBeforeEnter(el) {
      gsap.set(['li', 'h1'], {
         opacity: 0,
         x: 30,
      })
    },
    listEnter(el) {
    // ...
      gsap.timeline({ ease: 'back.out(10)', duration: .3})
        .to('h1',{ opacity: 1, x: 0, },)
        .to('li', { opacity: 1, x: 0, duration:1,
          stagger: { amount: 0.075, from: "end" },
        }, '<');
    },
    /* eslint-disable */
  },
};
</script>
