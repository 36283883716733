import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import generateApiEndpoint from '../utility/helper';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    db: [],
  },
  getters: {
    products(state) {
      return state.db.products;
    },
    quantityScoop(state) {
      if (state.db.liveProducts) {
        const { variations } = state.db.liveProducts.scoop;
        variations.forEach((variation) => {
          /* eslint-disable no-param-reassign */
          variation.catalogId = state.db.liveProducts.scoop.id;
          variation.quantityCount = variation.name.replace(/ (Scoop)s*/i, '');
          variation.img = `btn-${variation.name.toLowerCase().replace(' ', '-')}.jpg`;
          variation.previewImg = `preview-${variation.name.toLowerCase().replace(' ', '-')}.jpg`;
          /* eslint-disable no-param-reassign */
        });
        return variations;
      }
      return [];
    },
    sizeMilshake(state) {
      if (state.db.liveProducts) {
        const { variations } = state.db.liveProducts.milkshake;
        variations.forEach((variation) => {
          /* eslint-disable no-param-reassign */
          variation.catalogId = state.db.liveProducts.milkshake.id;
          variation.img = `btn-milkshake-${variation.name.toLowerCase()}.jpg`;
          variation.previewImg = `preview-milkshake-${variation.name.toLowerCase()}.jpg`;
          /* eslint-disable no-param-reassign */
        });
        return variations;
      }
      return [];
    },
    productSoda(state) {
      if (state.db.liveProducts) {
        const { variations } = state.db.liveProducts.soda_float;
        return variations[0];
      }
      return null;
    },
    pintProduct(state) {
      if (state.db.liveProducts) {
        const { variations } = state.db.liveProducts.pint;
        return variations;
      }
      return null;
    },
    productAffogato(state) {
      if (state.db.liveProducts) {
        const { variations } = state.db.liveProducts.affogato;
        return variations[0];
      }
      return null;
    },
    productIceCreamSandwich(state) {
      if (state.db.liveProducts) {
        const { variations } = state.db.liveProducts.ice_cream_sandwich;
        return variations;
      }
      return null;
    },
    flavors(state) {
      return state.db.flavors;
    },
    getMilk(state) {
      return state.db.milk;
    },
    getSoda(state) {
      return state.db.soda;
    },
    getToppings(state) {
      return state.db.addons.toppings;
    },
    build(state) {
      return state.db.builds;
    },
    buildScoop(state) {
      return state.db.builds ? state.db.builds.filter((build) => build.typeOfBuild === 'scoop') : [];
    },
    buildsMilkshake(state) {
      return state.db.builds ? state.db.builds.filter((build) => build.typeOfBuild === 'milkshake') : [];
    },
    buildsSoda(state) {
      return state.db.builds ? state.db.builds.filter((build) => build.typeOfBuild === 'soda') : [];
    },
    buildsAffogato(state) {
      return state.db.builds ? state.db.builds.filter((build) => build.typeOfBuild === 'affogato') : [];
    },
    buildsSandwich(state) {
      return state.db.builds ? state.db.builds.filter((build) => build.typeOfBuild === 'sandwich') : [];
    },
    cart(state) {
      return state.db.cart;
    },
    pickUpDateTime(state) {
      return state.db.pickUpDateTime;
    },
    getPath(state) {
      return state.db.path;
    },
  },
  mutations: {
    addDb(state, db) {
      // eslint-disable-next-line no-param-reassign
      state.db = db;
    },
    clearBuild(state) {
      // eslint-disable-next-line no-param-reassign
      state.db.builds = [];
    },
    editBuild(state, {
      id,
      value,
      type,
      index,
    }) {
      state.db.builds.filter((build) => {
        if (build.id === id) {
          if (type === 'toppings') {
            // Override array with value (not entry in array)
            if (value !== 'toppings') {
              build[type] = value; // eslint-disable-line no-param-reassign
            }
          } else if (type === 'cookie') {
            // Set appropriate information for chosen cookie. Cookie build starts with an empty variant ID.
            build.variantId = value.id;
            build.price = value.amount / 100;
            build.cookie = value.name;
          } else {
            build[type][index] = value; // eslint-disable-line no-param-reassign
          }
        }
        return build;
      });
    },
    addToBuilder(state, data) {
      state.db.builds.push(data);
    },
    addToCart(state, data) {
      if (!state.db.cart.length) {
        // eslint-disable-next-line no-param-reassign
        state.db.cart = [...state.db.cart, ...data];
      } else {
        // eslint-disable-next-line no-param-reassign
        state.db.cart = state.db.cart.reduce((acc, cur) => {
          if (!acc.length) {
            // eslint-disable-next-line no-param-reassign
            acc = state.db.cart;
          }
          data.forEach((inner) => {
            if (cur.id !== inner.id && acc.every((accItem) => accItem.id !== inner.id)) {
              acc.push(inner);
            }
          });
          return acc;
        }, []);
      }
    },
    clearCart(state) {
      // eslint-disable-next-line no-param-reassign
      state.db.cart = [];
    },
    remove(state, { id, el }) {
      el.forEach((elem) => {
        // eslint-disable-next-line no-param-reassign
        state.db[elem] = state.db[elem].filter((item) => item.id !== id);
      });
    },
    saveTotal(state, total) {
      // eslint-disable-next-line no-param-reassign
      state.db.totalPrice = total;
    },
    savePickUpDate(state, dateTime) {
      // eslint-disable-next-line no-param-reassign
      state.db.pickUpDateTime = dateTime;
    },
    clearState(state) {
      /* eslint-disable no-param-reassign */
      state.db.builds = [];
      state.db.cart = [];
      state.db.totalPrice = '';
      state.db.pickUpDateTime = '';
    },
    setPath(state, path) {
      // eslint-diable-next-line no-param-reassign
      state.db.path = path;
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    fetchProducts({ commit }) {
      try {
        axios.all([
          axios.get(generateApiEndpoint('db')),
          axios.get(generateApiEndpoint('flavors')),
          axios.get(generateApiEndpoint('products')),
          axios.get(generateApiEndpoint('products/addons')),
        ])
          .then(axios.spread((db, flavors, products, addons) => {
            const d = db.data;
            const f = flavors.data;
            for (let i = 0; i < f.length; i += 1) {
              f[i].id = f[i].name;
            }
            f.sort((a, b) => a.name.localeCompare(b.name));

            addons.data.toppings.sort((a, b) => a.name.localeCompare(b.name));

            d.flavors = f;
            d.liveProducts = products.data;
            d.addons = addons.data;
            commit('addDb', d);
          }));
      } catch (e) {
        console.log(e);
      }
    },
    previewOrder({ state }) {
      return new Promise((resolve) => {
        const lineItems = [];
        state.db.cart.forEach((item) => {
          const lineItem = {
            cid: item.catalogId,
            vid: item.variantId,
            price: item.price,
            flavors: item.flavors,
          };
          if (item.typeOfBuild === 'scoop') {
            lineItem.toppings = item.toppings;
          } else if (item.typeOfBuild === 'milkshake') {
            [lineItem.milk] = item.milk;
          } else if (item.typeOfBuild === 'soda') {
            [lineItem.soda] = item.soda;
          }
          lineItems.push(lineItem);
        });

        axios.post(generateApiEndpoint('order/preview'), {
          items: lineItems,
        })
          .then((result) => {
            resolve(result.data);
          });
      });
    },
    validateBuild({ state }) {
      // Ensure selections are made for each item (e.g. flavor, milk and/or soda)
      let isValid = true;
      let name = '';
      let variantId = '';
      let total = 0;
      let quantity = 0;
      state.db.builds.forEach((item) => {
        variantId = item.variantId;
        name = item.title;
        total += item.price;
        quantity += 1;

        if (item.flavors.includes('flavors')
            || (item.typeOfBuild === 'milkshake' && item.milk[0] === 'milk')
            || (item.typeOfBuild === 'soda' && item.soda[0] === 'soda')
            || (item.typeOfBuild === 'sandwich' && item.variantId === null)) {
          isValid = false;
        }
      });

      // Facebook Pixel
      if (isValid && process.env.NODE_ENV === 'production') {
        // eslint-disable-next-line no-undef
        fbq('track', 'AddToCart', {
          content_name: name,
          content_category: 'Ice Cream',
          content_ids: [variantId],
          content_type: 'product',
          contents: [{
            id: variantId,
            quantity,
          }],
          value: total,
          currency: 'USD',
        });
      }

      return new Promise((resolve) => resolve(isValid));
    },
  },
});
