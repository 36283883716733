<template>
  <header class="main-header">
    <div class="container">
      <div class="header-nav">
        <a @click="$router.go(-1)" v-if="isBack">
          <img src="@/assets/images/img01.svg" alt="arrow" width="27">
        </a>
      </div>
      <div
        :class="[
          'logo',
          {'thank-you': this.$route.name === 'ThankYou'},
        ]"
        v-if="!isLogo"
      >
        <Router-link class="basket" to="/">
          <img src="@/assets/images/logo.png" alt="logo" width="102">
        </Router-link>
      </div>
      <div class="header-control">
        <div class="basket-holder" v-visible="isCart">
          <div class="count">{{ cart ? cart.length : '' }}</div>
          <Router-link class="basket" :to="$options.links.cart">
            <img src="@/assets/images/img02.png" alt="basket" width="22">
          </Router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import ROUTES from '@/constans/route';

export default {
  links: {
    home: ROUTES.HOME,
    cart: ROUTES.CART,
  },
  name: 'Header',
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    ...mapGetters(['cart']),
    isLogo() {
      return this.$route.meta.hideLogo;
    },
    isBack() {
      return this.$route.name !== 'Home' && this.$route.name !== 'ThankYou';
    },
    isCart() {
      return this.$route.name !== 'ThankYou';
    },
  },
  methods: {
    handleHome() {
      if (this.cart.length > 0) {
        this.showDialog = true;
        this.$store.commit('clearCart');
      }
    },
  },
};
</script>

<style scoped>

</style>
