<template>
  <div id="app">
    <Header />
    <transition @before-enter="beforeEnter" @enter="enter"
    :css="false" mode="out-in" appear>
      <router-view />
    </transition>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import { gsap } from 'gsap';

const DEFAULT_TRANSITION = 'none';
export default {
  name: 'App',
  components: {
    Header,
  },
  data() {
    return {
      transitionName: DEFAULT_TRANSITION,
    };
  },
  mounted() {
    this.$store.dispatch('fetchProducts');
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      /* eslint-disable  */
      let { transitionName } = to.meta || from.meta;
      // overwrite transitionname
      if (to.name === 'Options') {
        if (to.params.name === 'scoop') transitionName = 'scoop-option';
        else if (to.params.name === 'milkshake') transitionName = 'listings'
      }
      this.transitionName = transitionName;
      next();
    });
  },
  watch: {
    '$route' (to, from) {
      if (from.path === '/products/scoop') {
        this.transitionName = `ext-${this.transitionName}`
      } else if (from.path === '/products/scoop/builder' && to.path === '/products/scoop') {
        this.transitionName = `ext-${this.transitionName}`;
      }
    }
  },
  methods: {
    /* eslint-disable */
    beforeEnter(el) {
    },
    enter(el, done) {
       switch (this.transitionName) {
        case 'listings':
            this.entShow(done);
          break;
        case 'scoop-option':
           this.entShow(done);
          break;
        case 'ext-scoop-option':
          this.extScoop(done);
          break;
        case 'ext-listings': 
           this.extListings(done);
           break;
        default:
          gsap.from(el, {
            opacity: 0,
            duration: 0.35,
            onComplete: done
          })
      }
    },
    afterEnter(el) {
    },
    beforeLeave(el) {
    },
    entShow(done) {
       gsap.timeline({ ease: 'back.out(4)', duration:.3})
            .from('h1',{ opacity: 0, x: 30, },)
            .from('li', { opacity: 0, x: 30,
              stagger: { amount: 0.25, from:"end" },
              onComplete:done
        }, '<');
    },
    extScoop(done) {
      gsap.timeline({ ease: 'back.out(4)', duration:.3,})
            .from('h1',{ opacity: 0, y: 30,})
            .from('li', { opacity: 0, y: 30,
              stagger: { amount: 0.25, from: "end" },
              onComplete:done
            }, '<',);
    },
    extListings(done) {
      gsap.timeline({ ease: 'back.out(4)', duration:.3,})
            .from('h1',{ opacity: 0, x: -30, },)
            .from('li', { opacity: 0, x: -30,
              stagger: { amount: 0.25, from: "end"},
              onComplete:done
        }, '<',);
    },
    /* eslint-disable */
  }
};
</script>

<style lang="scss">

</style>
