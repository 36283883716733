const ROUTES = {
  HOME: '/',
  PRODUCTS: '/products',
  PRODUCT_OPTIONS: '/products/:name',
  PRODUCT_BUILDER: '/products/:name/builder',
  CART: '/cart',
  CHECKOUT: '/checkout',
  THANK_YOU: '/thank-you',
};

export default ROUTES;
