<template>
  <transition name="fade">
    <div class="dialog-container" v-if="isShow">
      <div :class="['dialog-section',this.type]">
        <img src="@/assets/images/ic-error.svg">
        <div class="inner-text">
          <h1 v-if="title != ''" v-html="title"></h1>
          <p v-if="message != ''" v-html="message"></p>
          <slot v-else></slot>
        </div>
        <div
          class="close"
          @click="$emit('close-dialog')"
        />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    type: {
      type: String,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
  .dialog-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #69727B46;
    z-index: 99;
  }
  .dialog-section {
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    width: 85%;
    padding: 30px 20px;
    border-radius: 4px;
    background: var(--white);
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    transform: translateY(-50%);
    text-align: center;
    font-size: 13pt;

    .inner-text {
      margin-top: 14px;
    }

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      --size: 13px;
      width: 15px;
      height: 15px;
      color: var(--red);
    }

    &.error {
      color: var(--red);
    }

    &.warning {
      background-color: #FFFFE0;
    }

    &.success {
      background-color: #fff;
    }
  }
</style>
