import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import ROUTES from '@/constans/route';

Vue.use(VueRouter);

const suffix = '| Shop(pe) Online Order';

const routes = [
  {
    path: ROUTES.HOME,
    name: 'Home',
    component: Home,
    meta: {
      title: `What would you like? ${suffix}`,
      metaTags: [
        {
          name: 'description',
          content: 'Skip the line or order your ice cream ahead of time by ordering online.',
        },
        {
          name: 'og:description',
          content: 'Skip the line or order your ice cream ahead of time by ordering online.',
        },
        {
          name: 'og:type',
          content: 'website',
        },
        {
          name: 'og:site_name',
          content: 'Shop(pe) Ice Cream & Shop',
        },
        {
          name: 'og:title',
          content: 'Shop(pe) Online Order',
        },
        {
          name: 'og:image',
          // eslint-disable-next-line global-require
          content: document.location.href + require('@/assets/images/img05.jpg'),
        },
        {
          name: 'og:url',
          content: document.location.href,
        },
      ],
      transitionName: 'listings',
    },
  },
  {
    path: ROUTES.PRODUCT_OPTIONS,
    name: 'Options',
    component: () => import('@/views/ProductOptions.vue'),
    meta: {
      title: '-',
      transitionName: 'option',
    },
  },
  {
    path: ROUTES.PRODUCT_BUILDER,
    name: 'Builder',
    component: () => import('@/views/Builder.vue'),
    meta: {
      title: '-',
      transitionName: 'build',
    },
  },
  {
    path: ROUTES.CART,
    name: 'Cart',
    component: () => import('@/views/Cart.vue'),
    meta: {
      title: `Cart ${suffix}`,
    },
  },
  {
    path: ROUTES.CHECKOUT,
    name: 'Checkout',
    component: () => import('@/views/Checkout.vue'),
    meta: {
      hideLogo: true,
      title: `Checkout ${suffix}`,
    },
  },
  {
    path: ROUTES.THANK_YOU,
    name: 'ThankYou',
    component: () => import('@/views/ThankYou.vue'),
    meta: {
      hideLogo: false,
      title: `Thank you for your order ${suffix}`,
    },
  },
];

const router = new VueRouter({
  routes,
  base: process.env.NODE_ENV === 'staging' ? '/staging/' : '/',
  mode: 'history',
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);
  // Find the nearest route element with meta tags
  const nearestWithMeta = to.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);
  // If a route with a title was found, set the document (page) title to that value
  if (nearestWithTitle) {
    const { meta } = nearestWithTitle;
    // eslint-disable-next-line prefer-destructuring
    let title = meta.title;

    // Overwrite title
    if (to.name === 'Options') {
      if (to.params.name === 'scoop') title = 'How many scoops?';
      else if (to.params.name === 'pint') title = 'Pick your flavors';
      else if (to.params.name === 'milkshake') title = 'Milshake size?';
      else if (to.params.name === 'soda') title = 'Build your soda float';

      title += ` ${suffix}`;
    } else if (to.name === 'Builder') {
      title = `Build your ${to.params.name} ${suffix}`;
    }
    document.title = title;
  }
  // Remove any stale meta tags from the document using the key attribute we set below
  Array.from(document.querySelectorAll('[data-custom]')).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none
  if (!nearestWithMeta) return next();
  // Turn the meta tag definitions into actual elements in the head
  nearestWithMeta.meta.metaTags.map((tagDef) => {
    const tag = document.createElement('meta');
    Object.keys(tagDef).forEach((key) => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we cerated so we don't interfere with other ones.
    tag.setAttribute('data-custom', '');

    return tag;
  })
    .forEach((tag) => document.head.appendChild(tag));

  return next();
});

export default router;
