<template>
  <div class="holder-select">
    <span>{{ selectChange }}</span>
    <select
      v-if="type !== 'toppings'"
      @change="handleSelect(id, $event, indexOfList)"
      v-model="selectChange"
      :multiple="multiple"
    >
      <option
        v-for="option in options"
        :key="option.id"
        :value="option.name"
      >{{ option.name }}</option>
    </select>
    <multiselect
      v-if="type === 'toppings'"
      @input="handleMultiSelect(id, $event, indexOfList)"
      v-model="selectChange"
      :multiple="true"
      :close-on-select="false"
      :options="options"
      :searchable="false"
      label="name"
      track-by="name"
      placeholder="Toppings"
      :allowEmpty="true"
      :taggable="false"
    >
      <template
        slot="selection"
        slot-scope="{ values }">
          <span v-if="values.length">{{ values.length }} options selected</span>
      </template>
    </multiselect>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Select',
  data() {
    return {
      selectChange: this.type !== 'toppings' ? this.type : '',
    };
  },
  props: {
    options: {
      type: Array,
    },
    id: {
      type: Number,
    },
    type: {
      type: String,
    },
    indexOfList: {
      type: Number,
      default: 0,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['getToppings', 'productIceCreamSandwich']),
  },
  mounted() {
    this.$store.commit('editBuild', {
      id: this.id,
      value: this.selectChange,
      type: this.type,
      index: this.indexOfList,
    });
  },
  methods: {
    handleSelect(id, { target: { value } }, index) {
      // These fields come with extra information; replace value with object containing additional data
      if (this.type === 'cookie') {
        this.productIceCreamSandwich.forEach((cookie) => {
          if (cookie.name === value) {
            // eslint-disable-next-line no-param-reassign
            value = cookie;
          }
        });
      }
      this.$store.commit('editBuild', {
        id,
        value,
        type: this.type,
        index,
      });
    },
    handleMultiSelect(id, value, index) {
      this.$store.commit('editBuild', {
        id,
        value,
        type: this.type,
        index,
      });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="scss">
  .holder-select {
    position: relative;
    width: 100%;

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--bdr);
      border: var(--input-border-width) solid var(--input-border-color);
      line-height: var(--select-line-height);
      color: var(--white);
      font-size: var(--input-font-size);
      background: var(--red);
      text-transform: uppercase;
      font-weight: var(--font-weight-bold);
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    select {
      opacity: 0;
      z-index: 1;
    }
  }
</style>

<style>
  .multiselect__tags {
      background: var(--red);
      border: none;
      border-radius: var(--bdr);
      padding: 8px 8px 0 8px;
  }

  .multiselect__select {
    display: none;
  }

  .multiselect__option--highlight:after {
    content: '';
  }

  .multiselect__placeholder {
    text-align: center;
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
  }

  .multiselect__content-wrapper,
  .multiselect__option--selected,
  .multiselect__option--highlight,
  .multiselect__option--selected.multiselect__option--highlight,
  .multiselect__option--selected.multiselect__option--highlight:after {
    background: var(--red);
  }

  .cell-options .multiselect span,
  .multiselect__option--selected:after {
    display: block;
    color: white;
  }

  .multiselect__option--selected.multiselect__option--highlight:after {
    content: 'Selected';
  }
</style>
